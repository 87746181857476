<h1 mat-dialog-title>Update {{ data.projectName }}</h1>
<div mat-dialog-content height="20%">
  <form novalidate [formGroup]="updateProjectForm" method="POST">
    <mat-form-field appearance="fill" class="user-input">
      <mat-label>Update Name</mat-label>
      <input
        matInput
        formControlName="updateProjectName"
        placeholder="Untitled"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" class="user-input">
      <mat-label>Update Visibility</mat-label>
      <mat-select matInput formControlName="updateVis">
        <mat-option value="public">public</mat-option>
        <mat-option value="private">private</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="user-input">
      <mat-label>List owners</mat-label>
      <input
        matInput
        formControlName="updateOwners"
        placeholder="Ex. alice@gmail.com, bob@gmail.com"
      />
      <mat-hint align="end"
        >Enter emails as a list separated by commas</mat-hint
      >
    </mat-form-field>

    <mat-form-field appearance="fill" class="user-input">
      <mat-label>List editors (Optional)</mat-label>
      <input
        matInput
        formControlName="updateEditors"
        placeholder="Ex. alice@gmail.com, bob@gmail.com"
      />
      <mat-hint align="end"
        >Enter emails as a list separated by commas</mat-hint
      >
    </mat-form-field>

    <div mat-dialog-actions>
      <button mat-raised-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button (click)="onUpdateProject()" cdkFocusInitial>
        Update
      </button>
    </div>
  </form>
</div>
