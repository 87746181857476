<div id="banner">
  <h1 id="heading">Accio!</h1>
  <p style="font-size: large">
    Welcome to the ultimate magic wand tool! Command your wand to "summon" all
    your necessary masks with speedy and succinct incantations!
  </p>
</div>

<app-create-project></app-create-project>

<div class="container">
  <h1>Search by:</h1>
  <mat-form-field hintLabel="Clear to remove this filter" appearance="fill">
    <mat-label>Project Name</mat-label>
    <input matInput [(ngModel)]="filterTag" placeholder="Ex. MyProject" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Visibility</mat-label>
    <mat-select [(ngModel)]="filterVisibility">
      <mat-option value="all">all</mat-option>
      <mat-option value="public">public</mat-option>
      <mat-option value="private">private</mat-option>
    </mat-select>
    <mat-hint align="end">Default: all</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Role</mat-label>
    <mat-select [(ngModel)]="filterRole">
      <mat-option value="owner">owner</mat-option>
      <mat-option value="editor">editor</mat-option>
    </mat-select>
    <mat-hint align="end">Default: both</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Sort List</mat-label>
    <mat-select [(ngModel)]="filterSort">
      <mat-option value="dsc">newest to oldest</mat-option>
      <mat-option value="asc">oldest to newest</mat-option>
    </mat-select>
    <mat-hint align="end">Default: newest</mat-hint>
  </mat-form-field>

  <mat-form-field
    hintLabel="Clear this field to remove this filter"
    appearance="fill"
  >
    <mat-label>Project ID</mat-label>
    <input matInput [(ngModel)]="filterId" placeholder="Ex. ajkhweAfdaDFsh" />
  </mat-form-field>

  <button mat-raised-button (click)="fetchProjects()">Update filter(s)</button>

  <div id="gallery">
    <div *ngFor="let project of projects" class="gallery-item">
      <div class="project-item row">
        <a [routerLink]="['/img-gallery', project['projId']]">
          <div class="col-95">
            <div class="project-text">
              {{ project["name"] }}
              <p class="timestamp">
                <i>Last modified: {{ formatDateTime(project["timestamp"]) }}</i>
              </p>
            </div>
          </div>
        </a>
        <div class="project-menu col-05" align="right">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu1"
            aria-label="Project menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu1="matMenu">
            <button
              mat-menu-item
              (click)="
                updateButton(
                  project['projId'],
                  project['name'],
                  project['visibility'],
                  project['owners'],
                  project['editors']
                )
              "
            >
              <mat-icon>update</mat-icon>
              <span>Change project settings</span>
            </button>
            <button mat-menu-item (click)="deleteButton(project['projId'])">
              <mat-icon>delete</mat-icon>
              <span>Delete project</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<app-end-page></app-end-page>
