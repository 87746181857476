<!-- Toggle buttons -->
<mat-button-toggle-group 
  #group="matButtonToggleGroup" 
  vertical style="border:none" 
  [value]="maskTool" (change)="updateTool(group.value)">
  <mat-checkbox (change)="doPreview()">
    Preview Floodfill
  </mat-checkbox>
  <mat-button-toggle mat-icon-button value="MAGIC-WAND-ADD" title="Add Mask with floodfill    W">
    <mat-icon class="custom-icon" svgIcon="bucket_add"></mat-icon>
    <p>Floodfill</p>
  </mat-button-toggle>
  <mat-button-toggle mat-icon-button value="MAGIC-WAND-SUB" title="Erase Mask with floodfill    Q">
    <mat-icon class="custom-icon" svgIcon="bucket_sub"></mat-icon>
    <p>Fill-erase</p>
  </mat-button-toggle>
  <mat-button-toggle mat-icon-button value="PAINT" title="Paint Brush    B">
    <mat-icon>brush</mat-icon>
    <p>Brush</p>
  </mat-button-toggle>
  <mat-button-toggle mat-icon-button value="ERASE" title="Eraser    E">
    <mat-icon class="custom-icon" svgIcon="eraser"></mat-icon>
    <p>Eraser</p>
  </mat-button-toggle>
  <div>
    <p style="color:white">Brush Width</p>
    <mat-slider 
      title="Brush Width    ↓3  4↑"  
      (change)="updateWidth()"
      vertical min="1" max="80" step="1" [(ngModel)]="brushWidth">
    </mat-slider>
  </div>
  <mat-button-toggle mat-icon-button value="PAN" title="Pan    P">
    <mat-icon class="button">pan_tool</mat-icon>
    <p>Pan</p>
  </mat-button-toggle>
  <mat-button-toggle mat-icon-button value="MASK-ONLY" title="Mask Only    M">
    <mat-icon>image</mat-icon>
    <p>See Mask</p>
  </mat-button-toggle>
</mat-button-toggle-group>

<div class="large-break"></div>

<!-- Slider -->
<div>
  <p style="color:white">Tint</p>
  <mat-slider 
    title="Mask Tint    T to toggle"  
    (change)="updateMaskAlpha(maskAlphaValue)"
    vertical min="0" max="1" step=".01" [(ngModel)]="maskAlphaValue">
  </mat-slider>
  <br/>
  <mat-form-field class="slider-value">
    <mat-label style="color=white">Tint</mat-label>
    <input 
      (change)="updateMaskAlpha(maskAlphaValue)" 
      matInput type="number" 
      [(ngModel)]="maskAlphaValue" 
      style="color=white">
  </mat-form-field>
</div>