<!-- Form to save Mask -->
<form novalidate [formGroup]="uploadMaskForm" (ngSubmit)="onSubmit()"  
      *ngIf="displayMaskForm" method="POST" enctype="multipart/form-data" id="mask-form">
  <h1>Save Mask As:</h1>
  <mat-form-field appearance="fill">
    <mat-label>Mask Name</mat-label>
    <input required matInput formControlName="maskName" placeholder="Mask Name"/>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Mask Labels</mat-label>
    <input matInput formControlName="tags" placeholder="Tags (optional)"/>
    <mat-hint>Enter list seperated by commas: "tag 1, tag 2, tag 3"</mat-hint>
  </mat-form-field>
  <button [disabled]="disableSubmit" type="submit" mat-raised-button id="save-button">Save Mask As</button>
  <!-- Save(Update) Image-->
  <button mat-raised-button type="click" (click)="onClickSaveButton()">Save Mask</button>
  <!-- Save(Update) Image-->
</form>

<!-- Editor -->
<app-top-toolbar
  (clearMaskEvent)="clearMask()"
  (invertMaskEvent)="invertMask()"
  (undoRedoEvent)="undoRedo($event)"
  (switchImageEvent)="switchImage($event)"
  (newToleranceEvent)="updateTolerance($event); updatePreview();"
  (newZoomEvent)="zoom($event)">
</app-top-toolbar>

<mat-drawer-container id="img-editor">
  <mat-drawer id="toolbar" mode="side" opened>
    <app-toolbar 
      (newMaskToolEvent)="updateMaskTool($event)"
      (newMaskAlphaEvent)="updateMaskAlpha($event)"
      (newWidthEvent)="updateBrushWidth($event)"
      (newPreviewEvent)="getIsPreview($event)">
    </app-toolbar>
  </mat-drawer>

  <mat-drawer-content>
      <!-- Retrieves new mask via output event emiter -->
    <div id="stage" [style.width.px]="stageWidth" [style.height.px]="stageHeight">
      <canvas #scaledCanvas appMask id="mask-layer"
        width="100px" height="100px"
        [originalImageData]="originalImageData"
        [scale]="scaleFactor"
        [disableFloodFill]="disableFloodFill"
        [tool]="maskTool"
        [translationCoords]="destinationCoords"
        (newPaintEvent)="startDraw($event)"
        (continuePaintEvent)="drawPixel($event)"
        (newPaintMaskEvent)="doMaskActionPaint()"
        (newMouseMoveEvent)="setCursorPosition($event)"
        (newMouseOutEvent)="setCursorOut()"
        (newPanEvent)="pan($event)"
        (newDestinationEvent)="setDestinationCoords($event)"
        (newZoomEvent)="zoom($event)"
        (floodfillEvent)="getFloodfillSet($event)"
        (scribbleFillEvent)="getScribbleSet($event)">
      </canvas>

      <canvas #previewCanvas id="preview-layer"
        width="100px" height="100px">
      </canvas>

      <canvas #cursorCanvas id="cursor-layer"
        width="100px" height="100px">
      </canvas>

      <canvas #imageCanvas id="image-layer"
        width="100px" height="100px">
      </canvas>
      
      <canvas #maskCanvas style="display:none">
      </canvas>

      <canvas #paintCanvas style="display:none">
      </canvas>

    </div>
  </mat-drawer-content>
</mat-drawer-container>

<div class="end-page"></div>
